




















































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
@Component({
  components: {},
})
export default class dialog_edit_estadisticas extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;

  public get dias_semana() {
    return dieta_visualizacionModule.datos_para_estadisticas.dias;
  }
  public get comidas_semana() {
    return dieta_visualizacionModule.datos_para_estadisticas.comidas;
  }

  created() {}

  public close() {
    if (this.dias_semana.filter((x) => x.cheked === true).length === 0) {
      this.dias_semana.forEach((x) => (x.cheked = true));
    }
    if (this.comidas_semana.filter((x) => x.cheked === true).length === 0) {
      this.comidas_semana.forEach((x) => (x.cheked = true));
    }
    this.$emit("recalcular");
    this.syncedDialog=false;
  }
}
